.video-wrapper--native .plyr__video-wrapper, .video-wrapper--native video {
    height: 100%;
}

.video-wrapper__image-wrapper {
    opacity: 1;
    visibility: visible;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.video-wrapper__image-wrapper, .video-wrapper__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.video-wrapper__image {
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center";
}

.icon--play {
    filter: drop-shadow(0 2px 2px #0006);
}

.video-wrapper .icon--play {
    opacity: 1;
    width: 80px;
    height: 80px;
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

@media screen and (any-hover: hover) {
    .video-wrapper:hover .icon--play {
        transform: scale(1.15);
    }
}

.video-item {
    max-width: 855px;
    margin: 20px auto;
}

.video-item--stretch {
    max-width: none;
}

.video-item[aria-hidden="true"] {
    display: none;
}

.video-item .video-wrapper iframe {
    visibility: hidden;
    width: 0;
    height: 0;
}

.video-item.is-playing .video-wrapper__image-wrapper {
    visibility: hidden;
    opacity: 0;
}

.video-item.is-playing iframe {
    visibility: visible;
    width: 100%;
    height: 100%;
}

.model-wrapper {
    padding-bottom: 100%;
    position: relative;
}

.model-wrapper .shopify-model-viewer-ui, .model-wrapper model-viewer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.mosaic {
    margin: 0 -10px -20px;
}

.mosaic__item {
    padding: 0 10px 20px;
}

.mosaic__item > :first-child {
    min-height: 240px;
}

.mosaic--large .mosaic__item > :first-child {
    min-height: 280px;
}

@media screen and (max-width: 999px) {
    .mosaic--two-columns .mosaic__column:first-child .mosaic__item > :first-child, .mosaic--three-columns .mosaic__column:nth-child(2) .mosaic__item > :first-child {
        min-height: 340px;
    }
}

@media screen and (min-width: 641px) {
    .mosaic {
        margin: 0 -15px -30px;
    }

    .mosaic__item {
        padding: 0 15px 30px;
    }
}

@media screen and (min-width: 1000px) {
    .mosaic {
        display: flex;
    }

    .mosaic__column {
        flex-direction: column;
        flex: 1 0;
        display: flex;
    }

    .mosaic__item {
        flex-direction: column;
        flex: 1 0 auto;
        display: flex;
    }

    .mosaic__item > :first-child {
        flex: 1 0 auto;
        height: 100%;
        min-height: 260px;
    }

    .mosaic--small .mosaic__item > :first-child {
        min-height: 225px;
    }

    .mosaic--large .mosaic__item > :first-child {
        min-height: 300px;
    }

    .mosaic--two-columns .mosaic__column:first-child, .mosaic--three-columns .mosaic__column:nth-child(2) {
        flex-grow: 1.38;
    }
}

.nav-dropdown {
    background: var(--secondary-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    border-radius: 0 0 3px 3px;
    padding: 16px 0;
    list-style: none;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
    position: absolute;
    top: 100%;
}

.nav-dropdown:not(.nav-dropdown--floating) {
    left: -15px;
}

.nav-dropdown--floating {
    border: none;
    border-radius: 3px;
    margin-top: 7px;
}

.nav-dropdown--floating:before {
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -2px #0000001a, 5px 0 5px -2px #0000001a, 0 -5px 5px -2px #0000001a;
}

.nav-dropdown--glued:not(.nav-dropdown--inverse) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.nav-dropdown--glued.nav-dropdown--inverse {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.nav-dropdown--fixed {
    min-height: 420px;
}

.nav-dropdown--restrict {
    max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 40px);
    overscroll-behavior: contain;
    overflow: auto;
}

@supports (--css: variables) {
    .nav-bar .nav-dropdown .nav-dropdown {
        max-height: calc(100vh - var(--distance-to-top, 0px)  - 40px);
        overscroll-behavior: contain;
        overflow: auto;
    }
}

.nav-dropdown[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
}

.nav-dropdown .icon--nav-triangle-borderless {
    z-index: 1;
    width: 20px;
    height: 9px;
    position: absolute;
    bottom: 100%;
    left: 16px;
}

.nav-dropdown .icon--nav-triangle-left {
    z-index: 1;
    width: 9px;
    height: 20px;
    position: absolute;
    top: 23px;
    right: 100%;
}

.nav-dropdown__item:not(.has-mega-menu) {
    position: relative;
}

.nav-dropdown__link {
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 2px 60px 2px 20px;
    display: flex;
}

.nav-dropdown__link svg {
    width: 8px;
    height: 12px;
    margin: 0 -40px 0 65px;
    position: relative;
    top: 1px;
}

.nav-dropdown__link[aria-expanded="true"] {
    color: var(--accent-color);
}

.nav-dropdown .nav-dropdown {
    border-radius: 0 3px 3px 0;
    min-width: 0;
    margin-top: 0;
    top: -17px;
    left: 100%;
}

.nav-dropdown--inverse .nav-dropdown {
    border-radius: 3px 0 0 3px;
    left: auto;
    right: 100%;
}

.nav-dropdown--inverse .nav-dropdown .icon--nav-triangle-left {
    left: 100%;
    right: auto;
    transform: rotateZ(180deg);
}

.nav-dropdown .nav-dropdown--floating {
    border-left: 1px solid var(--border-color);
    top: -16px;
}

.mega-menu {
    background: var(--secondary-background);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    visibility: hidden;
    opacity: 0;
    border-radius: 0 0 3px 3px;
    width: 100vw;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
    position: absolute;
    top: 100%;
    left: 0;
}

.mega-menu--floating {
    max-width: 0;
}

.mega-menu[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
}

.mega-menu__inner {
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100%;
    max-height: 100%;
    padding: 25px 0 0;
    display: flex;
    overflow: auto;
}

.mega-menu__inner--center {
    justify-content: center;
}

.mega-menu__promo {
    white-space: normal;
    text-align: center;
    flex: 0 230px;
    width: 230px;
    min-width: 200px;
    margin: 0 0 30px 50px;
    display: block;
}

.mega-menu__promo + .mega-menu__promo {
    margin-left: 20px;
}

.mega-menu__column-list {
    flex-wrap: wrap;
    flex: auto;
    min-width: 0;
    margin: 0 -40px;
    display: flex;
}

@supports (display: grid) {
    .mega-menu__column-list {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        display: grid;
    }
}

.mega-menu__column {
    flex: 0 auto;
    margin: 0 30px 30px;
    display: block;
}

.mega-menu:not(.mega-menu--floating) .mega-menu__column:first-child {
    margin-left: 0 !important;
}

.mega-menu:not(.mega-menu--floating) .mega-menu__column:last-child {
    margin-right: 0 !important;
}

.mega-menu__title {
    margin-bottom: 10px;
    display: block;
}

.mega-menu__linklist {
    list-style: none;
}

.mega-menu__link {
    padding: 5px 0 6px;
    line-height: 1.4;
    display: block;
}

.mega-menu__image-wrapper {
    border-radius: 3px;
    margin: 5px 0 22px;
    overflow: hidden;
}

.mega-menu__promo .aspect-ratio {
    transition: transform .6s ease-in-out;
    transform: scale(1.01);
}

.features--animate-zoom .mega-menu__promo:hover .aspect-ratio {
    transform: scale(1.06);
}

.mega-menu__promo:hover .mega-menu__image-heading {
    color: var(--accent-color);
}

.mega-menu__image-heading {
    margin-bottom: 7px;
    transition: color .2s ease-in-out;
    display: block;
}

.mega-menu__image-text {
    line-height: 1.5;
}

.nav-bar .mega-menu {
    max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 40px);
    overscroll-behavior: contain;
    overflow: auto;
}

@media screen and (min-width: 1280px) {
    .mega-menu__column {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mega-menu__inner--large .mega-menu__column {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mega-menu__promo {
        flex-basis: 290px;
        width: 290px;
    }
}

@media screen and (min-width: 1440px) {
    .mega-menu__inner--center .mega-menu__column {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mega-menu__inner--large .mega-menu__column {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mega-menu__promo {
        min-width: 240px;
    }
}

.mega-menu--floating {
    border: none;
    border-left: 1px solid var(--border-color);
    border-radius: 0 3px 3px 0;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 100%;
}

.mega-menu--floating:before {
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 5px 5px -2px #0000001a, 5px 0 5px -2px #0000001a, 0 -5px 5px -2px #0000001a;
}

.mega-menu--floating .mega-menu__inner {
    margin: 0;
    padding-left: 25px;
    padding-right: 30px;
}

.mega-menu--floating .mega-menu__title {
    margin-bottom: 5px;
}

.mega-menu--floating .mega-menu__column {
    white-space: normal;
    flex: 0 0 170px;
    margin-left: 30px;
    margin-right: 30px;
    display: block;
}

.mega-menu--floating .mega-menu__column-list {
    margin: 0 -30px;
}

.nav-bar {
    display: none;
}

@media screen and (min-width: 1000px) {
    .nav-bar {
        border-bottom: 1px solid var(--border-color);
        background: var(--secondary-background);
        width: 100%;
        display: block;
        position: relative;
        left: 0;
    }

    .nav-bar:after {
        content: "";
        background: linear-gradient(to right, rgba(var(--secondary-background-rgb), .1), rgba(var(--secondary-background-rgb), .6) 35%, var(--secondary-background));
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .nav-bar__item {
        margin-right: 35px;
        padding: 17px 0;
        display: inline-block;
        position: relative;
    }

    .nav-bar__item--static {
        position: static;
    }

    .nav-bar__item > .nav-dropdown {
        min-width: calc(100% + 20px);
    }

    .nav-bar__link {
        cursor: pointer;
        display: block;
    }

    .nav-bar__link .icon--arrow-bottom {
        width: 12px;
        height: 8px;
        margin-left: 10px;
    }

    .nav-bar__link .icon--nav-triangle {
        opacity: 0;
        z-index: 2;
        width: 20px;
        height: 9px;
        margin-left: -16px;
        transition: all .2s ease-in-out;
        position: absolute;
        bottom: -1px;
    }

    .nav-bar__link[aria-expanded="true"] {
        color: var(--accent-color);
    }

    .nav-bar__link[aria-expanded="true"] .icon--nav-triangle {
        opacity: 1;
    }
}

.mobile-menu {
    visibility: hidden;
    z-index: 1;
    opacity: 0;
    color: var(--text-color);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 16px));
    will-change: transform;
    width: 100vw;
    height: 100vh;
    max-height: 0;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out, visibility .25s ease-in-out, max-height 0s linear .25s;
    position: absolute;
    top: 100%;
    left: 0;
    transform: scale(.9);
}

.mobile-menu .icon--arrow-right, .mobile-menu .icon--arrow-left {
    width: 8px;
    height: 12px;
}

.mobile-menu[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
    transition: opacity .4s cubic-bezier(0, 1, .4, 1), transform .4s cubic-bezier(.18, 1.25, .4, 1), visibility .4s linear;
    transform: scale(1);
}

.mobile-menu__inner {
    max-width: 100vw;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.mobile-menu .icon--nav-triangle-borderless {
    z-index: 1;
    width: 18px;
    height: 8px;
    position: absolute;
    bottom: 100%;
    left: 20px;
}

.mobile-menu__panel {
    background: var(--secondary-background);
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    transition: transform .5s cubic-bezier(.23, 1, .32, 1), visibility .5s cubic-bezier(.23, 1, .32, 1);
    position: relative;
    overflow: hidden auto;
}

.mobile-menu__panel.is-nested {
    visibility: hidden;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
}

.mobile-menu__section {
    padding: 16px 20px;
}

.mobile-menu__section--loose {
    padding-top: 18px;
    padding-bottom: 18px;
}

.mobile-menu__section:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.mobile-menu__section.is-sticky {
    background: var(--secondary-background);
    z-index: 1;
    position: sticky;
    top: 0;
}

.mobile-menu__nav {
    list-style: none;
}

.mobile-menu__nav-link {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0 7px;
    display: flex;
}

.mobile-menu__back-button {
    text-align: left;
    width: 100%;
    display: block;
}

.mobile-menu__back-button svg {
    vertical-align: -1px;
    margin-right: 8px;
}

.mobile-menu__nav-list {
    margin-top: 2px;
}

.mobile-menu__nav-list-toggle {
    text-align: left;
    width: 100%;
    padding: 4px 0;
}

.mobile-menu__nav-list-toggle svg {
    width: 12px;
    height: 8px;
    margin-left: 12px;
    transition: transform .3s ease-in-out;
}

.mobile-menu__nav-list-toggle[aria-expanded="true"] svg {
    transform: rotateZ(180deg);
}

.mobile-menu__nav-list-item {
    margin-bottom: 22px;
}

.mobile-menu__nav-collapsible {
    height: 0;
    transition: all .3s ease-in-out;
    overflow: hidden;
}

.mobile-menu__nav-collapsible-content {
    margin-top: 4px;
}

.mobile-menu__promo {
    text-align: center;
    padding: 20px 0 25px;
    display: block;
}

.mobile-menu__image-wrapper {
    border-radius: 3px;
    margin-bottom: 22px;
    overflow: hidden;
}

.mobile-menu__image-heading {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 18px));
    margin-bottom: 0;
    display: block;
}

.mobile-menu__image-text {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 15px));
}

.mobile-menu__promo-list {
    flex-flow: row;
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
}

.mobile-menu__promo-list .mobile-menu__promo-item {
    white-space: normal;
    scroll-snap-align: center;
    flex: none;
    width: 81%;
}

.mobile-menu__promo-list .mobile-menu__promo {
    margin-left: 10px;
    margin-right: 10px;
}

.scroller .mobile-menu__promo-list:before {
    content: "";
    flex: 0 0 20px;
}

.scroller .mobile-menu__promo-list:after {
    content: "";
    flex: 0 0 13px;
}

@supports (padding: max(0px)) {
    .mobile-menu__section:last-child {
        padding-bottom: max(16px, env(safe-area-inset-bottom, 0px)  + 16px);
    }
}

@media screen and (min-width: 641px) {
    .mobile-menu .icon--nav-triangle-borderless {
        left: 40px;
    }
}

.mobile-menu[aria-hidden="false"] .mobile-menu__panel.is-open {
    visibility: visible;
    transform: translateX(0);
}

.mobile-menu__help-wrapper {
    align-items: center;
    display: flex;
}

.mobile-menu__help-wrapper .icon--bi-phone {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.mobile-menu__help-wrapper .icon--bi-email {
    width: 22px;
    height: 22px;
    margin-right: 18px;
}

.mobile-menu__help-wrapper + .mobile-menu__help-wrapper {
    margin-top: 18px;
}

.line-item__product-info-wrapper {
    align-items: flex-start;
    display: flex;
}

.line-item__image-wrapper {
    width: 60px;
    min-width: 60px;
    margin-right: 20px;
}

.line-item__meta {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
}

.line-item__vendor, .line-item__plan-allocation, .line-item__property-list {
    line-height: 1.55;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    margin-bottom: 7px;
    display: block;
}

.line-item__vendor {
    text-transform: uppercase;
}

.line-item__title {
    margin-bottom: 4px;
    line-height: 1.5;
    display: block;
}

.line-item__property-list {
    margin: 6px 0;
    line-height: 1.5;
    list-style: none;
}

.line-item__price {
    font-weight: var(--text-font-bolder-weight);
    color: var(--accent-color);
    display: inline-block;
}

.line-item__price--compare {
    color: var(--text-color);
}

.line-item__price--highlight {
    color: var(--product-on-sale-accent);
}

.line-item__price--compare {
    margin-left: 20px;
    position: relative;
}

.line-item__price--compare:before {
    content: "";
    background: rgba(var(--text-color-rgb), .7);
    width: calc(100% + 10px);
    height: 1px;
    position: absolute;
    top: 50%;
    left: -5px;
}

.line-item__price-info {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-top: -2px;
}

.line-item__discount-list {
    margin-top: 2px;
    list-style: none;
}

.line-item__discount {
    font-weight: var(--text-font-bolder-weight);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    background: rgba(var(--product-on-sale-accent-rgb), .08);
    color: var(--product-on-sale-accent);
    border-radius: 2px;
    padding: 0 11px;
    display: inline-block;
}

.line-item__discount svg {
    vertical-align: text-bottom;
    margin-right: 6px;
}

.line-item__quantity-remove {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-left: 10px;
    line-height: 1;
    display: inline-block;
}

@media screen and (max-width: 640px) {
    .line-item-table, .line-item-table tbody {
        width: auto;
        display: block;
    }

    .line-item--stack {
        white-space: normal;
        display: block;
    }

    .line-item--stack:first-child {
        border-top: none !important;
    }

    .line-item--stack .line-item__image-wrapper {
        width: 80px;
        min-width: 80px;
    }

    .line-item--stack .line-item__product-info {
        padding-top: 20px;
        padding-bottom: 20px;
        display: block;
    }

    .line-item--stack .line-item__title {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    }

    .line-item--stack .line-item__price {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    }

    .line-item--stack .line-item__quantity {
        margin-top: 12px;
    }
}

@media screen and (min-width: 641px) {
    .line-item__product-info-wrapper {
        align-items: center;
    }

    .line-item__title {
        white-space: normal;
    }

    .line-item__image-wrapper {
        width: 90px;
        min-width: 90px;
    }

    .line-item__quantity-remove {
        width: max-content;
        margin: 10px auto 0;
        display: block;
    }
}

.fulfillment-item {
    border-top: none !important;
}

.fulfillment-item td {
    padding-top: 0 !important;
}

.order-foot {
    border-top: 1px solid var(--border-color);
}

.order-foot__item {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px 0;
    display: flex;
}

.order-foot__item + .order-foot__item {
    border-top: 1px solid var(--border-color);
}

.order-foot__item--highlight, .order-foot__item--strong {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 15px));
    font-weight: var(--text-font-bolder-weight);
}

.order-foot__item--highlight {
    color: var(--product-on-sale-accent);
}

.order-foot__item--strong {
    color: var(--heading-color);
}

@media screen and (max-width: 640px) {
    .card .order-foot {
        margin-left: 20px;
    }
}

@media screen and (min-width: 641px) {
    .order-foot__inner {
        max-width: 470px;
        margin-left: auto;
    }

    .order-foot__item {
        padding-left: 20px;
        padding-right: 30px;
    }
}

.order-summary__alert {
    margin: 21px 0 5px;
}

@media screen and (min-width: 641px) {
    .order-summary__alert {
        margin: 24px 0 12px;
    }
}

.address-list, .address-list__item + .address-list__item {
    border-top: 1px solid var(--border-color);
}

.address-list__item {
    padding: 24px 20px;
}

.address-list__action-list {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-top: 14px;
}

.address-list__action-list > :last-child {
    margin-left: 18px;
}

@media screen and (max-width: 640px) {
    .address-list, .address-list__item + .address-list__item {
        border-top: none;
        display: block;
        position: relative;
    }

    .address-list:before, .address-list__item + .address-list__item:before {
        content: "";
        background: var(--border-color);
        width: calc(100% - 20px);
        height: 1px;
        position: absolute;
        top: 0;
        left: 20px;
    }
}

@media screen and (min-width: 641px) {
    .template-addresses .card__section--tight {
        padding-right: 20px;
    }

    .address-list {
        flex-wrap: wrap;
        display: flex;
    }

    .address-list__item {
        box-shadow: 0 1px var(--border-color), 1px 0 var(--border-color);
        flex-direction: column;
        flex: 0 0 50%;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        border-top: none !important;
    }
}

@media screen and (min-width: 1280px) {
    .address-list__item {
        flex-basis: 33.3333%;
    }
}

.page__header {
    justify-content: space-between;
    align-items: flex-end;
    margin: 28px 0;
    display: flex;
}

.page__header--stack {
    flex-direction: column;
    align-items: flex-start;
}

.page__header--centered {
    text-align: center;
    justify-content: center;
}

.page__header--stack.page__header--centered {
    align-items: center;
}

.page__header--image {
    text-shadow: 1px 2px 4px #0003;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
    justify-content: center;
    min-height: 250px;
    padding: 25px;
    position: relative;
    overflow: hidden;
}

.page__header--image.page__header--overlay:before {
    content: "";
    background: linear-gradient(#0000, #0000004d);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.shopify-section:first-child .page__header--image {
    border-radius: 0;
    margin-top: 0 !important;
}

.page__header-image {
    object-position: center;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.page__header-image--placeholder {
    fill: #696969;
    background: #a9a9a9;
}

.page__header-content {
    z-index: 1;
}

.page__title {
    margin-bottom: 0;
}

.page__sub-header {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin: 23px 0;
    display: none;
}

.page__description {
    margin: 10px 0 0;
}

.page__button-action {
    margin-top: 20px;
}

.page__tag-list {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    margin: 12px -20px -10px;
    list-style: none;
}

.page__tag-item {
    margin: 0 20px 10px;
    display: inline-block;
    position: relative;
}

.page__tag-item.is-selected {
    color: var(--accent-color);
}

.page__tag-item.is-selected:after {
    content: "";
    background: currentColor;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.page__meta {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    margin-top: 8px;
}

.page__meta-item:not(:last-child):after {
    content: "";
    background: rgba(var(--text-color-rgb), .4);
    vertical-align: middle;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    margin: 0 10px;
    display: inline-block;
}

.page__navigation-item svg {
    width: 6px;
    height: 9px;
}

.page__navigation-item svg path {
    stroke-width: 1.5px;
}

.page__navigation-item--prev svg {
    margin-right: 9px;
}

.page__navigation-item--next svg {
    margin-left: 9px;
}

.page__navigation-item--prev + .page__navigation-item--next:before {
    content: "";
    background: var(--text-color);
    vertical-align: -2px;
    width: 1px;
    height: 12px;
    margin: 0 15px;
    display: inline-block;
}

.page__content {
    margin-bottom: 40px;
}

@media screen and (min-width: 641px) {
    .page__header {
        margin: 35px 0;
    }

    .page__header--image {
        min-height: 430px;
    }

    .page__sub-header {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }

    .page__sub-header + .page__header {
        margin-top: 20px;
    }

    .page__meta {
        margin-top: 10px;
        font-size: 1rem;
    }

    .page__meta-item:not(:last-child):after {
        margin: 0 16px;
    }

    .page__content {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 16px));
        margin-bottom: 90px;
    }
}

@media screen and (min-width: 1000px) {
    .page__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .page__header--centered {
        align-items: center;
    }
}

.popover {
    background: var(--secondary-background);
    color: var(--text-color);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    will-change: transform;
    width: 100vw;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out, visibility .25s ease-in-out;
    position: absolute;
    top: 100%;
    left: 0;
    transform: scale(.9);
}

.popover .icon--nav-triangle-borderless {
    right: 67px;
}

.popover--large {
    text-align: center;
}

.popover[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity .4s cubic-bezier(0, 1, .4, 1), transform .4s cubic-bezier(.18, 1.25, .4, 1), visibility .4s linear;
    transform: scale(1.001);
}

.popover__inner {
    padding: 15px 20px 20px;
}

.popover__inner--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.popover .icon--nav-triangle-borderless {
    z-index: 2;
    filter: drop-shadow(0 -2px 2px #0000001f);
    width: 18px;
    height: 8px;
    position: absolute;
    bottom: 100%;
}

.popover__header {
    padding: 12px 0 30px;
}

.popover__title {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 20px));
    margin-bottom: 4px;
}

.popover__legend {
    margin-bottom: 16px;
}

.popover__secondary-action {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-top: 16px;
}

.popover__secondary-action > p {
    margin-bottom: 0;
}

@media screen and (max-width: 640px) {
    .popover__panel-list, .popover__panel {
        height: 100% !important;
    }

    .popover__inner {
        -webkit-overflow-scrolling: touch;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        display: flex;
        overflow: auto;
    }
}

@media screen and (min-width: 641px) {
    .popover {
        border-radius: 3px;
        width: auto;
        min-width: 160px;
        top: calc(100% + 15px);
        left: auto;
        right: -2px;
        box-shadow: 0 1px 5px 2px #0000001a;
    }

    .popover .icon--nav-triangle-borderless {
        right: 27px;
    }

    .popover--large {
        min-width: 320px;
    }

    .popover__inner {
        padding: 15px 25px;
    }

    .popover__inner--restrict {
        max-height: 340px;
        overflow: auto;
    }

    .popover__header {
        padding-bottom: 15px;
    }

    .popover__title {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 18px));
        margin-bottom: 8px;
    }
}

@media screen and (min-width: 1280px) {
    .popover {
        top: calc(100% + 4px);
    }
}

.popover__linklist {
    white-space: nowrap;
    list-style: none;
}

.popover__link-item {
    cursor: pointer;
    width: 100%;
    padding: 2px 25px;
    transition: color .15s ease-in-out, background .15s ease-in-out;
    display: block;
}

.popover__link-item:hover {
    background: var(--accent-background);
    color: var(--accent-color);
}

.popover__panel-list {
    transition: height .2s ease-in-out;
    position: relative;
    overflow: hidden;
}

.popover__panel {
    visibility: hidden;
    width: 100%;
    transition: transform .5s cubic-bezier(.23, 1, .32, 1), visibility .5s cubic-bezier(.23, 1, .32, 1);
}

.popover__panel--default {
    transform: translateX(-100%);
}

.popover__panel--sliding {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
}

.popover[aria-hidden="false"] .popover__panel.is-selected {
    visibility: visible;
}

.popover__panel.is-selected {
    transform: translateX(0);
}

.popover--password .icon--nav-triangle-borderless {
    right: 21px;
}

.popover--currency, .popover--locale {
    min-width: 0;
}

@media screen and (min-width: 641px) {
    .popover--password .popover__inner {
        padding-bottom: 25px;
    }

    .popover--password .icon--nav-triangle-borderless {
        right: calc(50% - 13px);
    }

    .popover--currency {
        top: calc(100% + 3px);
        right: -27px;
    }

    .popover--currency .icon--nav-triangle-borderless {
        right: 23px;
    }

    .popover--locale {
        top: calc(100% + 3px);
        right: -26px;
    }

    .popover--locale .icon--nav-triangle-borderless {
        right: 23px;
    }

    .popover--logged {
        right: -47px;
    }

    .popover--logged .icon--nav-triangle-borderless {
        right: 50px;
    }

    .popover--unlogged {
        right: -5px;
    }

    .popover--unlogged .icon--nav-triangle-borderless {
        right: 6px;
    }
}

@media screen and (min-width: 1280px) {
    .popover--password {
        top: calc(100% + 15px);
        right: -2px;
    }

    .popover--logged {
        right: -32px;
    }

    .popover--logged .icon--nav-triangle-borderless {
        right: 29px;
    }

    .popover--unlogged {
        right: 0;
        transform: scale(.9)translateX(calc(50% - 7px));
    }

    .popover--unlogged .icon--nav-triangle-borderless {
        left: calc(50% - 8px);
    }

    .popover--unlogged[aria-hidden="false"] {
        transform: scale(1)translateX(calc(50% - 7px));
    }
}

.product-gallery {
    margin-bottom: 12px;
    position: relative;
}

.product-gallery__carousel-wrapper {
    margin-bottom: 25px;
    position: relative;
}

.product-gallery__carousel .flickity-viewport {
    transition: height .2s ease-in-out;
}

.product-gallery__carousel:not(.flickity-enabled) .product-gallery__carousel-item:not(.is-selected) {
    display: none;
}

.product-gallery .flickity-slider {
    will-change: unset;
}

.product-gallery__carousel-item {
    width: 100%;
    padding: 0 20px;
}

.product-gallery__carousel-item--hidden, .product-gallery__carousel-item[aria-hidden="true"] .plyr__control {
    visibility: hidden;
}

.product-gallery__carousel-item.is-filtered {
    display: none;
}

.product-gallery__size-limiter {
    margin: 0 auto;
}

.product-gallery__view-in-space {
    background: rgba(var(--text-color-rgb), .08);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.product-gallery__view-in-space[data-shopify-xr-hidden] {
    visibility: hidden;
}

@media screen and (min-width: 1000px) {
    .product-gallery__view-in-space[data-shopify-xr-hidden] {
        display: none;
    }
}

.product-gallery__view-in-space svg {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: -1px 8px 0 0;
}

.product-gallery__zoom-notice {
    display: none;
}

.product-gallery__zoom-notice svg {
    vertical-align: baseline;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.product-gallery__carousel--zoomable ~ .product-gallery__zoom-notice {
    text-align: center;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    width: 100%;
    margin: 12px 0 6px;
    display: block;
}

@media screen and (max-width: 640px) {
    .product-gallery__carousel {
        margin-left: calc(-1 * var(--mobile-container-gutter));
        margin-right: calc(-1 * var(--mobile-container-gutter));
    }
}

@media screen and (min-width: 1000px) {
    .product-gallery__carousel {
        margin: -4px;
    }

    .product-gallery__carousel-item {
        padding: 4px;
    }

    .product-gallery--with-thumbnails {
        flex-direction: row-reverse;
        align-items: flex-start;
        width: 100%;
        display: flex;
    }

    .product-gallery--with-thumbnails .product-gallery__carousel-wrapper {
        flex: 1 0 auto;
        margin: 10px 5px 0 42px;
    }
}

@media screen and (min-width: 1000px) and (any-hover: hover) {
    .product-gallery__carousel--zoomable ~ .product-gallery__zoom-notice {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
        margin-top: 26px;
    }

    .product-gallery__zoom-notice svg {
        margin-right: 14px;
    }
}

@media screen and (min-width: 1000px) {
    .product-gallery {
        margin-bottom: 0;
    }
}

@media screen and (hover: none) {
    .product-gallery__zoom-notice {
        display: none !important;
    }
}

.product-gallery__thumbnail {
    cursor: pointer;
    border: 2px solid #0000;
    border-radius: 3px;
    width: 60px;
    margin: 0 4px;
    padding: 2px;
    transition: border .2s ease-in-out;
    display: block;
    position: relative;
}

.product-gallery__thumbnail.is-nav-selected {
    border-color: var(--accent-color);
}

.product-gallery__thumbnail.is-filtered {
    display: none;
}

.product-gallery__thumbnail-badge {
    z-index: 1;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 3px;
}

@media screen and (max-width: 999px) {
    .product-gallery__thumbnail-list:after, .product-gallery__thumbnail-list:before {
        content: "";
        vertical-align: middle;
        width: calc(var(--mobile-container-gutter)  - 4px);
        display: inline-block;
    }

    .product-gallery__thumbnail {
        vertical-align: top;
        display: inline-block;
    }
}

@media screen and (min-width: 641px) and (max-width: 999px) {
    .product-gallery .scroller {
        margin: 0 -15px;
    }
}

@media screen and (min-width: 641px) {
    .product-gallery__thumbnail {
        width: 72px;
    }
}

@media screen and (min-width: 1000px) {
    .product-gallery__thumbnail-list {
        z-index: 1;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overscroll-behavior: contain;
        width: 96px;
        max-height: 450px;
        margin: -2px 0 -2px -3px;
        position: relative;
        overflow: auto;
    }

    .product-gallery__thumbnail-list::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .product-gallery__thumbnail {
        margin: 8px 4px;
    }
}

.product-meta {
    margin: -11px 0 5px;
    position: relative;
}

.product-meta__title {
    margin-bottom: 14px;
}

.modal .product-meta__title {
    margin-right: 45px;
}

.product-meta__label-list {
    margin-bottom: 17px;
    font-size: 0;
}

.product-meta__label-list .product-label + .product-label {
    margin-left: 5px;
}

.product-meta__reference {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-bottom: 3px;
}

.product-meta__reviews-badge {
    display: block;
}

.product-meta__vendor {
    text-transform: uppercase;
}

.product-meta__vendor + .product-meta__sku:before {
    content: "";
    background: var(--border-color);
    vertical-align: middle;
    width: 1px;
    height: 13px;
    margin: 0 14px;
    display: inline-block;
    position: relative;
    top: -1px;
}

.product-meta__share-buttons {
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    display: flex;
    position: relative;
}

.product-meta__share-buttons .social-media__item svg {
    opacity: .35;
}

.card__separator + .product-meta__share-buttons {
    margin-top: 0;
}

.product-meta__button, .product-meta__description, .product-meta__text {
    margin-bottom: 25px;
}

.product-meta__reviews-badge .rating__caption {
    margin-left: 12px;
}

.product-form__payment-container + .product-meta__description, .product-form__payment-container + .product-meta__text {
    margin-top: 25px;
    margin-bottom: 0;
}

@media screen and (min-width: 641px) {
    .product-meta__share-buttons {
        margin-top: 0;
    }

    .product-meta__label-list + .product-meta__share-buttons {
        margin-top: 22px;
    }

    .product-meta__reference, .product-meta__reviews-badge {
        margin-bottom: -2px;
    }

    .product-meta__reviews-badge {
        width: -moz-fit-content;
        width: fit-content;
        margin-top: -4px;
    }

    .product-meta__reference ~ .product-meta__share-buttons, .product-meta__reviews-badge ~ .product-meta__share-buttons {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .product-meta__reference + .product-meta__reviews-badge {
        margin-top: 5px;
    }

    .product-meta__reviews-badge .rating__caption {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    }
}

.product-form__variants {
    margin-bottom: 24px;
}

.product-form__option {
    margin-bottom: 23px;
}

.no-js .product-form__option:not(.no-js) {
    display: none;
}

.product-form__option-name {
    margin-bottom: 2px;
    display: block;
}

.product-form__selected-value {
    color: var(--text-color);
    padding-left: 2px;
}

.product-form__info-list {
    border-spacing: 0 22px;
    width: 100%;
    margin: -22px 0 6px;
    display: table;
}

.product-form__info-item {
    display: table-row;
}

.product-form__info-title {
    vertical-align: baseline;
    white-space: nowrap;
    padding-right: 12px;
    display: table-cell;
}

.product-form__info-item--quantity > * {
    vertical-align: middle;
    padding-top: 6px;
}

.product-form__info-content {
    width: 100%;
    display: table-cell;
}

.product-form__price-info {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    margin-top: 5px;
}

.product-form__price-info + .product-form__price-info {
    margin-top: 0;
}

shopify-payment-terms {
    margin-top: 8px;
    display: block;
}

.product-form .select-wrapper {
    vertical-align: middle;
    display: inline-block;
}

.product-form__quantity {
    vertical-align: middle;
    width: 65px;
    display: inline-block;
}

.product-form__inventory.inventory:before {
    margin-right: 14px;
}

.product-form__payment-container {
    flex-direction: column;
    display: flex;
}

.product-form__status-message {
    height: 0;
    margin-top: 18px;
    transition: height .25s ease-in-out;
    overflow: hidden;
}

.product-form .price--compare {
    top: -2px;
}

@media screen and (max-width: 640px) {
    .product-form__payment-container .shopify-payment-button {
        margin-top: 16px;
    }
}

@media screen and (min-width: 641px) {
    .product-form {
        margin-top: -8px;
    }

    .product-form__option-name {
        margin-bottom: 7px;
    }

    .product-form__variants {
        margin-bottom: 26px;
    }

    .product-form__payment-container {
        flex-flow: wrap;
        align-items: flex-start;
        margin: -9px;
    }

    .product-form__payment-container > * {
        flex: none;
        width: calc(50% - 18px);
        margin: 9px;
    }
}

.shopify-payment-button__button--unbranded {
    text-align: center;
    cursor: pointer;
    transition: background .25s ease-in-out, color .25s ease-in-out, box-shadow .25s ease-in-out;
    display: inline-block;
    position: relative;
    background: var(--secondary-button-background) !important;
    color: var(--secondary-button-text-color) !important;
    line-height: 48px !important;
    font-weight: var(--text-font-bolder-weight) !important;
    font-size: var(--base-text-font-size) !important;
    border-radius: 2px !important;
    padding: 0 30px !important;
}

.shopify-payment-button__button--unbranded:hover {
    background: rgba(var(--secondary-button-background-rgb), .8) !important;
}

.shopify-payment-button__button--branded {
    min-height: 48px;
}

.shopify-payment-button__button--branded .shopify-cleanslate > div {
    border-radius: 2px !important;
}

.shopify-payment-button__more-options {
    color: var(--link-color);
    margin-top: 8px;
    transition: color .2s ease-in-out;
}

.shopify-payment-button__more-options:not([disabled]):hover {
    color: var(--link-color-hover);
    text-decoration: none;
}

.shopify-payment-button__more-options[aria-hidden="true"], .product-block-list__item:empty {
    display: none;
}

.product-block-list__item--trust .icon--lock {
    color: var(--heading-color);
    width: 18px;
    height: 24px;
}

.product-block-list__item--shipping .shipping-estimator {
    margin-top: -.435em;
    margin-bottom: 10px;
}

.product-block-list__item--shipping .shipping-estimator__results {
    margin-top: 26px;
    margin-bottom: -10px;
}

.product__refund-policy-link {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.product__refund-policy-link svg {
    width: 8px;
    height: 12px;
}

@media screen and (max-width: 640px) {
    .product-block-list__item:first-child .card {
        border-top: none;
    }
}

@media screen and (max-width: 999px) {
    .product-block-list__wrapper {
        min-height: 0 !important;
    }

    .product-block-list__item--info > .card {
        top: 0 !important;
    }
}

@media screen and (min-width: 1000px) {
    .product-block-list {
        position: relative;
    }

    .product-block-list__wrapper {
        width: calc(50% - 15px);
        margin-left: 0;
    }

    .product-block-list__item--info {
        width: calc(50% - 15px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media screen and (min-width: 1280px) {
    .product-block-list--medium .product-block-list__wrapper {
        width: calc(55% - 15px);
    }

    .product-block-list--medium .product-block-list__item--info {
        width: calc(45% - 15px);
    }

    .product-block-list--large .product-block-list__wrapper {
        width: calc(60% - 15px);
    }

    .product-block-list--large .product-block-list__item--info {
        width: calc(40% - 15px);
    }
}

.product-info__block-item:empty {
    display: none !important;
}

.modal .featured-product .card {
    border: none;
}

.modal .featured-product .card:first-child {
    border-right: 1px solid var(--border-color);
}

@media screen and (min-width: 1000px) {
    .featured-product {
        table-layout: fixed;
        width: 100%;
        display: table;
    }

    .featured-product > * {
        vertical-align: top;
        width: 50%;
        display: table-cell;
    }

    .featured-product > :first-child {
        border-radius: 3px 0 0 3px;
    }

    .featured-product > :last-child {
        border-left: none;
        border-radius: 0 3px 3px 0;
    }
}

.product-label {
    color: #fff;
    font-weight: var(--text-font-bolder-weight);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    vertical-align: top;
    border-radius: 3px;
    width: max-content;
    padding: 4px 10px;
    line-height: 1;
    display: inline-block;
}

.product-label--on-sale {
    background: var(--product-on-sale-accent);
    color: var(--product-on-sale-color);
}

.product-label--custom1 {
    background: var(--product-custom-label-1-background);
    color: var(--product-custom-label-1-color);
}

.product-label--custom2 {
    background: var(--product-custom-label-2-background);
    color: var(--product-custom-label-2-color);
}

.price-list {
    align-items: baseline;
    line-height: 1;
    display: inline-flex;
}

.price {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 24px));
    color: var(--accent-color);
}

.price--highlight {
    color: var(--product-on-sale-accent);
    margin-right: .7em;
}

.price--compare {
    color: var(--text-color);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 16px));
    text-decoration: line-through;
    -webkit-text-decoration-color: rgba(var(--text-color-rgb), .7);
    text-decoration-color: rgba(var(--text-color-rgb), .7);
    position: relative;
}

.inventory {
    font-weight: var(--text-font-bolder-weight);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    padding-left: 1.4em;
    line-height: 1.2;
    display: block;
    position: relative;
}

.inventory:before {
    content: "";
    background: currentColor;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: .45em;
    left: 0;
}

.inventory--high {
    color: var(--product-in-stock-color);
}

.inventory--low {
    color: var(--product-low-stock-color);
}

.inventory-bar {
    background: var(--border-color);
    border-radius: 3px;
    width: 100%;
    height: 4px;
    margin: 16px 0 10px;
    display: block;
    position: relative;
    overflow: hidden;
}

.inventory-bar--hidden {
    display: none;
}

.inventory-bar__progress {
    width: 0;
    height: 100%;
    transition: width 1.15s ease-in-out;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.inventory--high + .inventory-bar > .inventory-bar__progress {
    background: var(--product-in-stock-color);
}

.inventory--low + .inventory-bar > .inventory-bar__progress {
    background: var(--product-low-stock-color);
}

@media screen and (min-width: 641px) {
    .inventory {
        font-size: 1rem;
    }

    .inventory-bar {
        margin-top: 23px;
    }
}

.color-swatch-list {
    margin: -2px -6px 0;
    padding-left: 4px;
}

.color-swatch {
    vertical-align: middle;
    margin: 6px;
    display: inline-block;
    position: relative;
}

.color-swatch__radio {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.color-swatch__item {
    cursor: pointer;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    position: relative;
}

.color-swatch__item svg {
    display: none;
}

.color-swatch__item-link {
    background-color: var(--secondary-background);
    box-shadow: 0 0 0 1px var(--form-border-color) inset;
    border-radius: 10px;
    width: auto;
    height: 18px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    display: none;
    position: relative;
}

.color-swatch__button {
    vertical-align: middle;
}

.color-swatch__button--labelled {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.color-swatch__label {
    vertical-align: middle;
    margin-left: 14px;
    display: inline-block;
}

.color-swatch__radio:checked + .color-swatch__item:after, .color-swatch__button.is-selected .color-swatch__item:after, .color-swatch__item.is-selected:after {
    content: "";
    border: 2px solid var(--accent-color);
    border-radius: 3px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    top: -4px;
    left: -4px;
}

.color-swatch__button.is-selected .color-swatch__label {
    font-weight: var(--text-font-bolder-weight);
    color: var(--accent-color);
}

.color-swatch--white .color-swatch__item {
    box-shadow: 0 0 0 1px var(--form-border-color) inset;
}

.color-swatch--view-more ~ .color-swatch, .color-swatch--view-more .color-swatch__item {
    display: none;
}

.color-swatch--view-more .color-swatch__item-link {
    display: block;
}

.color-swatch-list--large {
    margin: -4px -8px 0;
}

.color-swatch-list--large .color-swatch {
    margin: 8px;
}

.color-swatch-list--large .color-swatch__item {
    width: 36px;
    height: 36px;
}

.color-swatch-list--stack {
    margin: -3px -7px 0;
}

.color-swatch-list--stack .color-swatch__item {
    width: 16px;
    height: 16px;
}

.color-swatch-list--stack .color-swatch {
    margin: 7px;
    display: block;
}

.color-swatch--disabled .color-swatch__item:before {
    content: "";
    background: rgba(var(--secondary-background-rgb), .5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.color-swatch--disabled svg {
    z-index: 1;
    color: #4a4a4a;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 7px);
}

.block-swatch-list {
    margin: -3px -4px 0;
    padding-left: 2px;
}

.block-swatch {
    margin: 4px;
    display: inline-block;
}

.block-swatch__radio {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.block-swatch__item {
    text-align: center;
    border: 1px solid var(--form-border-color);
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(var(--border-color-rgb), .25);
    border-radius: 3px;
    padding: 6px 16px 7px;
    display: block;
}

.block-swatch__radio:checked + .block-swatch__item, .block-swatch__item.is-selected {
    box-shadow: 0 0 0 1px var(--accent-color);
    border-color: var(--accent-color);
    background: rgba(var(--accent-color-rgb), .06);
    color: var(--accent-color);
}

.block-swatch--disabled .block-swatch__item-text {
    opacity: .5;
    position: relative;
}

.block-swatch--disabled .block-swatch__item-text:after {
    /* content: "";
    background: currentColor; */
    width: calc(100% + 6px);
    height: 1px;
    position: absolute;
    top: calc(50% + 1px);
    left: -3px;
}

.variant-swatch-list {
    margin: -5px -5px -2px -4px;
}

.variant-swatch {
    vertical-align: middle;
    margin: 5px;
    display: inline-block;
}

.variant-swatch__radio {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.variant-swatch__item {
    cursor: pointer;
    border: 2px solid #0000;
    width: 64px;
    padding: 2px;
    display: block;
    position: relative;
    overflow: hidden;
}

.variant-swatch__item svg {
    display: none;
}

.variant-swatch__radio:checked + .variant-swatch__item, .variant-swatch__item.is-selected {
    border-color: var(--accent-color);
    border-radius: 3px;
}

.variant-swatch--disabled img {
    opacity: .5;
}

.variant-swatch--disabled svg {
    z-index: 1;
    color: #4a4a4a;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 7px);
}

.visual-filter-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
    display: grid;
}

.visual-filter {
    position: relative;
}

.visual-filter__radio {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.visual-filter__item {
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--form-border-color);
    box-shadow: 0 1px 1px rgba(var(--border-color-rgb), .25);
    border-radius: 3px;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    padding: 12px 12px 8px;
    font-size: .9em;
    line-height: 1.4;
    display: flex;
    position: relative;
}

.visual-filter__item img {
    width: 32px;
}

.visual-filter__radio:checked + .visual-filter__item, .visual-filter.is-selected {
    box-shadow: 0 0 0 1px var(--accent-color);
    border-color: var(--accent-color);
    background: rgba(var(--accent-color-rgb), .06);
    color: var(--accent-color);
}

.product-item {
    background: var(--secondary-background);
    white-space: normal;
    flex-shrink: 0;
    min-width: 0;
    padding: 20px;
    position: relative;
}

.product-item:after {
    content: "";
    box-shadow: 1px 0 var(--border-color), 0 1px var(--border-color);
    z-index: 2;
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.product-item__image-wrapper {
    margin-bottom: 20px;
    display: block;
}

.product-item__image-wrapper .placeholder-svg {
    height: auto;
}

.product-item__secondary-image {
    display: none;
}

.product-item__label-list {
    z-index: 2;
    font-size: 0;
    position: absolute;
    top: 11px;
    left: -1px;
}

.product-item__label-list > .product-label {
    border-radius: 0 3px 3px 0;
    margin-bottom: 5px;
    display: block;
}

.product-item__info {
    min-width: 0;
}

.product-item__price-list:first-child {
    margin-bottom: 10px;
}

.product-item__price-list {
    margin-top: -5px;
    margin-bottom: -5px;
}

.product-item__price-list .price {
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.product-item__vendor {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    text-transform: uppercase;
    margin-bottom: 6px;
    display: block;
}

.product-item__vendor, .product-item__title {
    line-height: 1.55;
    display: block;
}

.product-item__title {
    margin-bottom: 12px;
}

.product-item__title + .product-item__vendor {
    margin-top: -4px;
    margin-bottom: 12px;
}

.product-item__swatch-list {
    white-space: nowrap;
    margin: -2px 0 12px;
    overflow: hidden;
}

.product-item__price-info {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-top: 10px;
    line-height: 1;
}

.product-item .price {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 18px));
}

.product-item .price--compare {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
}

.product-item__reviews-badge {
    margin-top: 8px;
    display: block;
}

.product-item__inventory {
    margin-top: 10px;
}

.product-item__inventory:before {
    top: .3em;
}

.product-item__inventory {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
}

@media screen and (any-hover: hover) {
    .product-item__image-wrapper--with-secondary .product-item__primary-image, .product-item__image-wrapper--with-secondary .product-item__secondary-image {
        transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
    }

    .product-item__image-wrapper--with-secondary .product-item__secondary-image {
        object-fit: contain;
        object-position: center;
        visibility: hidden;
        opacity: 0;
        display: block;
    }

    .product-item__image-wrapper--with-secondary:hover .product-item__primary-image {
        opacity: 0;
        visibility: hidden;
    }

    .product-item__image-wrapper--with-secondary:hover .product-item__secondary-image {
        visibility: visible;
        opacity: 1;
    }
}

@media screen and (min-width: 641px) {
    .product-item__title {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    }

    .product-item--vertical .price:not(.price--compare), .product-item--list .price:not(.price--compare) {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 20px));
    }
}

@media screen and (min-width: 1000px) {
    .product-item__label-list {
        left: 0;
    }
}

.product-item--vertical .product-item__action-list, .product-item--horizontal .product-item__action-list {
    margin: 20px 0 5px;
}

.product-item--vertical .product-item__action-button, .product-item--horizontal .product-item__action-button {
    padding-left: 10px;
    padding-right: 10px;
}

.product-item--vertical .product-item__action-list--list-view-only, .product-item--vertical .product-item__action-button--list-view-only, .product-item--horizontal .product-item__action-list--list-view-only, .product-item--horizontal .product-item__action-button--list-view-only {
    display: none;
}

.product-item--vertical .product-item__action-button--list-view-only + .product-item__action-button, .product-item--horizontal .product-item__action-button--list-view-only + .product-item__action-button {
    margin-top: 0;
}

@media screen and (min-width: 1000px) {
    .product-item--vertical .product-item__action-list {
        margin: 15px -5px -5px;
    }

    .product-item--vertical .product-item__action-button {
        flex: 1 0 auto;
        margin: 5px !important;
    }
}

.product-item--horizontal, .product-item--list {
    display: flex;
}

.product-item--horizontal .product-item__info, .product-item--list .product-item__info {
    flex: 1 0 0;
    padding: 4px 0;
}

.product-item--horizontal .product-item__image-wrapper, .product-item--list .product-item__image-wrapper {
    flex: none;
    width: 120px;
    margin: 0 20px 0 0;
}

.product-item--horizontal .product-item__action-list, .product-item--list .product-item__action-list {
    align-self: center;
}

.product-item--horizontal .spr-starrating.spr-starrating {
    height: 11px;
}

.product-item--horizontal .spr-starrating .spr-icon.spr-icon {
    width: 11px;
    height: 11px;
}

@media screen and (max-width: 640px) {
    .product-item--horizontal, .product-item--list {
        width: 100% !important;
    }

    .product-list--collection .product-item__label-list, .product-list--stackable .product-item__label-list {
        left: 0;
    }

    .product-list--collection .rating__star, .product-list--stackable .rating__star {
        width: 11px;
        height: 11px;
    }
}

@media screen and (min-width: 641px) {
    .product-item--horizontal .product-item__image-wrapper {
        width: 110px;
        margin-right: 20px;
    }

    .product-item--horizontal .product-item__info {
        flex: 1 0 0;
    }
}

@media screen and (max-width: 640px) {
    .product-item__action-list {
        margin-top: 20px;
    }
}

@media screen and (min-width: 641px) {
    .product-item--list {
        align-items: center;
        padding-right: 25px;
        width: 100% !important;
    }

    .product-item--list .product-item__title {
        font-size: 1rem;
    }

    .product-item--list .product-item__image-wrapper {
        width: 180px;
        margin-right: 30px;
    }

    .product-item--list .product-item__info {
        flex: 1 0 0;
        justify-content: space-between;
        margin: 15px 0;
        display: flex;
    }

    .product-item--list .product-item__action-list {
        min-width: 180px;
        margin-left: 30px;
        display: flex;
    }
}

.product-item--compact .product-item__info {
    padding-top: 0;
}

.product-item--compact .product-item__price-list:first-child {
    margin-bottom: 8px;
}

.product-item--compact .product-item__reviews-badge {
    margin: 15px 0;
}

.complementary-product-list {
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    grid-auto-columns: min(74vw, 400px);
    grid-auto-flow: column;
    align-items: start;
    column-gap: 40px;
    margin-inline-start: -20px;
    margin-inline-end: -20px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    scroll-padding-inline: 20px;
    display: grid;
    overflow: auto;
}

.complementary-product-list:after {
    content: "";
}

.complementary-product-list::-webkit-scrollbar {
    display: none;
}

.product-item--complementary {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    flex-wrap: wrap;
    padding: 0;
}

.product-item--complementary:after {
    display: none;
}

.product-item--complementary .product-item__image-wrapper {
    width: 80px;
}

.product-item--complementary > .product-item__action-list {
    width: 100%;
    margin-bottom: 0;
}

@media screen and (min-width: 641px) {
    .complementary-product-list {
        border: 1px solid var(--border-color);
        grid: none;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
        scroll-padding-inline: 0;
    }

    .complementary-product-list::-webkit-scrollbar {
        display: none;
    }

    .complementary-product-list:after {
        content: "flickity";
        display: none;
    }

    .product-item--complementary {
        width: 50%;
        padding: 20px;
    }
}

.promo-block {
    border-radius: 3px;
    min-height: 240px;
    padding: 20px 24px;
    display: flex;
    position: relative;
    overflow: hidden;
}

.promo-block--small {
    min-height: 220px;
}

.promo-block--large {
    min-height: 260px;
}

.promo-block--top-left, .promo-block--top-center, .promo-block--top-right {
    align-items: flex-start;
}

.promo-block--middle-left, .promo-block--middle-center, .promo-block--middle-right {
    align-items: center;
}

.promo-block--bottom-left, .promo-block--bottom-center, .promo-block--bottom-right {
    align-items: flex-end;
}

.promo-block--top-right, .promo-block--middle-right, .promo-block--bottom-right {
    text-align: right;
    justify-content: flex-end;
}

.promo-block--top-center, .promo-block--middle-center, .promo-block--bottom-center {
    text-align: center;
    justify-content: center;
}

.promo-block--top-left, .promo-block--middle-left, .promo-block--bottom-left {
    text-align: left;
    justify-content: flex-start;
}

.promo-block__image-clip, .promo-block__image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.promo-block__image-clip {
    overflow: hidden;
}

.promo-block__image-wrapper {
    background-position: right calc(100% + 15px);
    background-repeat: no-repeat;
    background-size: auto 185px;
    transition: opacity .2s ease-in-out, background-position .35s ease-in-out;
}

.promo-block__image-wrapper--cover {
    transition: opacity .2s ease-in-out, transform 1.2s ease-in-out;
    transform: scale(1.01);
    background-position: center !important;
    background-size: cover !important;
}

.promo-block__inner {
    z-index: 1;
    position: relative;
}

.promo-block__content {
    margin-bottom: 1em;
}

.promo-block__heading {
    color: inherit;
    margin-bottom: 3px;
}

.promo-block__cta {
    margin-top: 3px;
}

.promo-block--small .promo-block__image-wrapper {
    background-size: auto 155px;
}

.promo-block--overlay:before {
    content: "";
    z-index: 1;
    background: linear-gradient(to top, #0000 15%, #00000080);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (any-hover: hover) {
    .promo-block__image-wrapper {
        background-position: right calc(100% + 35px);
    }

    .promo-block:hover .promo-block__image-wrapper {
        background-position: right calc(100% + 15px);
    }

    .features--animate-zoom .promo-block:hover .promo-block__image-wrapper--cover {
        transform: scale(1.07);
    }
}

@media screen and (min-width: 641px) {
    .promo-block {
        min-height: 270px;
        padding: 26px 30px;
    }

    .promo-block--small {
        min-height: 240px;
    }

    .promo-block--large {
        min-height: 300px;
    }
}
